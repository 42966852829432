.products-wrapper {
	margin-top: 2rem;
	color: white;
	overflow-x: hidden;
}
.product-page-title {
	font-size: 2.25rem;
	font-weight: bold;
	text-align: left;
}
.products-description {
	font-size: 1.25rem;
	font-weight: 400;
	text-align: justify;
	line-height: 2rem;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
	max-height: 300px !important;
}
.product-title {
	font-size: 1.75rem;
	font-weight: 700;
}
.product-content {
	font-weight: 500;
	line-height: 2rem;
	font-size: 1.25rem;
	text-align: justify;
}
.product-item-row {
	min-height: 300px;
	margin: 2rem 0rem;
}
@media only screen and (max-width: 480px) {
	.product-content {
		font-size: 1rem;
	}
	/* .container {
		padding-left: 1.5rem !important;
		padding-right: 1.5rem !important;
	} */
	.products-description {
		font-size: 1rem;
	}
}
