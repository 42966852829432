.formm-content-wrapper {
	padding: 3rem 0rem;
	padding-left: 5rem;
	padding-right: 3rem;
}
.success-content-wrapper {
	margin-top: -5rem;
	padding: 4rem 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}
.center-wrapper {
	padding-top: 2rem;
	display: flex;
	flex-direction: column;
	margin: auto;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.loader-text {
	color: rgb(112, 112, 112);
	font-size: 1.2rem;
	margin-top: 0.5rem;
	font-weight: 500;
}
.error-mail {
	color: rgb(209, 0, 0);
	font-weight: 500;
	font-size: 1.2rem;
	margin-top: 1rem;
}
.form-heading {
	font-size: 2rem;
	font-weight: 700;
}
.form-subheading {
	font-size: 1rem;
	font-weight: 400;
}
.heading-email {
	font-weight: 600;
	color: var(--primary-accent-color) !important;
}

.field-name {
	font-weight: 600;
	font-size: 1rem;
	margin-top: 1rem;
}

.text-field {
	margin-top: 0.5rem;
	width: 70%;
	height: 2.4rem;
	padding: 0.25rem 0.75rem;
	border-radius: 0.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(148, 148, 148, 0.744);
	font-weight: 500;
	display: block;
}

.error-text {
	display: block;
	color: rgb(209, 0, 0);
	font-weight: 500;
	margin-top: 0.3rem;
}
.disclaimer {
	color: rgb(67, 67, 67);
	font-size: 0.8rem;
	margin-top: 1rem;
	font-weight: 300;
}
input[type="text"]:focus {
	border-width: 1.5px !important;
	border-color: rgba(57, 57, 57, 0.744) !important;
}
.text-area {
	height: 5rem;
}
textarea:focus {
	border-width: 1.5px !important;
	border-color: rgba(57, 57, 57, 0.744) !important;
}
.form-email {
	font-weight: 700;
	color: var(--primary-accent-color) !important;
}
.mail-success {
	font-weight: 500;
	font-size: 1.2rem;
	word-wrap: break-word;
	color: rgb(112, 112, 112);
	text-align: center;
}
.ok-button {
	background-color: var(--primary-accent-color);
	padding: 1rem 2rem;
}
@media only screen and (max-width: 767px) {
	.success-content-wrapper {
		margin-top: 0;
	}
}
@media only screen and (max-width: 547px) {
	.text-field {
		width: 100%;
	}
	.form-heading {
		font-size: 1.5rem;
	}
	.form-subheading {
		font-size: 0.85rem;
		font-weight: 500;
	}
	.heading-email {
		font-size: 0.9rem;
	}
}
@media only screen and (max-width: 490px) {
	.formm-content-wrapper {
		padding: 3rem 1rem;
	}
}
