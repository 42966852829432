@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.service-card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-block: 20px;
	background-color: white;
	border-radius: 10px;
	border-style: none;
	padding-inline: 35px;
	padding-block: 40px;
	max-width: 280px;
}
.service-link {
	color: var(--primary-color) !important;
	font-weight: 700;
	text-decoration: none;
	font-size: 1rem;
	margin-top: 1rem;
	cursor: pointer;
	display: block;
}
.service-card:hover .service-img {
	scale: 1.3;
}
.service-img {
	height: 100px;
	transition: 0.4s;
}
.montserrat {
	font-family: "Montserrat", sans-serif;
}
.contact {
	font-weight: 500;
	font-size: 0.9rem;
	color: white;
	margin: 0;
	padding-inline: 10px;
}
.service-section-title {
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	margin-top: 10px;
	margin-bottom: 0;
	font-size: 1.75rem;
	text-align: center;
}
.service-subtitle {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 1.35rem;
	text-align: center;
	margin-top: 0;
}
.service-content {
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 1.2rem;
	color: rgb(144, 144, 144);
	margin-bottom: 5px;
}
@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.service-card {
		margin-inline: 10%;
		padding-inline: 20px;
		padding-block: 15px;
		margin-top: 6px;
	}
	.service-content {
		font-size: 0.9rem;
	}
	.service-subtitle {
		font-size: 1.1rem;
	}
	.service-section-title {
		font-size: 1.4rem;
	}
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
	/* For medium devices: */
	.service-card {
		height: 480px;
	}
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
	/* For large devices: */
	.service-card {
		height: 510px;
	}
}
@media only screen and (max-width: 3000px) and (min-width: 1200px) {
	/* For extra large devices: */
	.service-card {
		height: 450px;
		max-width: 350px;
	}
}
