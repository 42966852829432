@import "../../utils/constants.css";
@import "../../assets/fonts/friz/font.css";

.cust-nav {
	position: fixed;
	display: flex;
	flex-direction: row;
	width: 100%;
	z-index: 2000;
}
.brand-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.brand-logo {
	height: 75px;
	display: block;
}
.brand {
	font-family: "Friz Quadrata Std Medium", serif;
	font-size: 1.2rem;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
}
.linkk {
	/* display: inline-block; */
	position: relative;
	text-decoration: none;
}

.hover-2:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 3px;
	transform: scaleX(0);
	/* background-color: #201035; */
	background-color: var(--primary-color);
	transition: transform 0.3s;
}
.hover-2:hover:after {
	transform: scaleX(1);
}

.selected-nav:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 3px;
	transform: scaleX(1);
	background-color: var(--primary-color);
}

.nav-item {
	font-weight: 600;
	text-align: center;
	font-size: 1.1rem;
	margin-top: 20px;
	color: var(--primary-color);
}
.dropdown-menu {
	padding: 1rem 0.5rem !important;
}
.dropdown-item {
	padding: 0.5rem 1.5rem !important;
	font-weight: 400 !important;
	transition: 0.25s;
	cursor: pointer;
}
.dropdown-item:hover {
	background-color: var(--primary-color) !important;
	color: white !important;
	font-weight: 500 !important;
}
.cust-divider {
	border-top: 0.5px solid;
}
.svg-bg {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='visual' width='100%25' height='100%25' version='1.1'%3E%3Cpath d='M0 124L53.3 124.7C106.7 125.3 213.3 126.7 320 117.5C426.7 108.3 533.3 88.7 640 88.3C746.7 88 853.3 107 960 119C1066.7 131 1173.3 136 1280 134C1386.7 132 1493.3 123 1546.7 118.5L1600 114L1600 0L1546.7 0C1493.3 0 1386.7 0 1280 0C1173.3 0 1066.7 0 960 0C853.3 0 746.7 0 640 0C533.3 0 426.7 0 320 0C213.3 0 106.7 0 53.3 0L0 0Z' fill='%23ffffff' fillold='%23ed9510' strokeLinecap='round' strokeLinejoin='miter'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	overflow: visible;
}

.nav-item-link {
	padding: 10px 5px 5px 5px;
	cursor: pointer;
}
.hide-when-big {
	display: flex;
	justify-content: flex-end;
}
@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.hide-when-small {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	/* For desktops: */
	.hide-when-big {
		display: none;
	}
}
@media only screen and (max-width: 924px) {
	.nav-item {
		font-size: 0.9rem;
	}
}
a {
	color: #212529 !important;
}
