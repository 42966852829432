.contact-page-wrapper {
	margin: 2rem 4rem;
	font-family: "Montserrat", sans-serif;
}

.contact-wrapper {
	padding-right: 0 !important;
	padding-left: 0 !important;
	width: 100%;
	background-color: var(--primary-accent-color);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.contact-content-wrapper {
	padding: 3rem 2rem;
	color: white;
}
.contact-heading {
	font-weight: 700;
	font-size: 1.75rem;
}

.contact-subheading {
	font-weight: 300;
	font-size: 1rem;
	color: rgb(225, 225, 225);
}
.email {
	color: white;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	margin: 0;
	margin-top: 0.75rem;
	font-size: 1rem;
}
.whatsapp-link {
	color: white !important;
}
a {
	overflow-wrap: break-word !important;
}
.item-wrapper {
	margin: 0;
	margin-top: 2.75rem;
}
.item-heading {
	font-weight: 700;
	font-size: 1.25rem;
}
.item-subheading {
	font-weight: 300;
	font-size: 0.85rem;
	color: rgb(236, 236, 236);
	margin-top: 0.25rem;
	overflow-wrap: normal;
}
.form-wrapper {
	padding-left: 0 !important;
	width: 100%;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

@media only screen and (max-width: 1076px) {
	.email {
		font-size: 0.7rem;
	}
}
@media only screen and (max-width: 768px) {
	.email {
		font-size: 1rem;
	}
}
@media only screen and (max-width: 418px) {
	.email {
		font-size: 0.75rem;
	}
	.contact-page-wrapper {
		margin: 1rem 0.5rem;
	}
}
