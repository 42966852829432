.side-drawer {
	height: 100%;
	background: white;
	position: fixed;
	top: 0;
	left: 0;
	width: 60%;
	z-index: 2002;
	padding: 40px 30px;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
}
.side-drawer.open {
	transform: translateX(0);
}

.side-drawer-brand {
	margin: 20px;
}
.side-drawer-nav-item {
	font-weight: 700;
	font-size: 1.1rem;
}

@media (max-width: 450px) {
	.brand-name {
		font-size: 0.8rem;
	}
	.side-drawer-brand {
		margin: 20px 0px 20px 0px;
	}
}
