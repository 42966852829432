.home-wrapper {
	overflow-x: hidden;
}
.home-header {
	font-size: 3.5rem;
	color: aliceblue;
	font-weight: 700;
}
.home-brand {
	font-family: "Friz Quadrata Std Medium", serif;
	font-size: 4rem;
	word-wrap: break-word;
	font-weight: 500;
	text-align: left;
	color: rgb(226, 226, 226);
}
.home-subtext {
	font-size: 1.2rem;
	color: rgb(226, 226, 226);
	font-weight: 400;
	text-align: justify;
}
.active-bullet {
	opacity: 1;
	background-color: red;
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.bottom-margin-when-small {
		margin-bottom: 2rem;
		margin-top: 1.5rem;
	}
	.home-header {
		font-size: 2.5rem;
	}
}

a {
	text-decoration: none !important;
}
