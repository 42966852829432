.footer-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 230px;
	width: 100%;
	background-color: rgb(37, 37, 37);
	color: white;
	padding: 2rem 5rem;
}

.first-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.links-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
.footer-item {
	margin: 0 1rem;
	color: white !important;
	font-size: 1.2rem;
	transform: scale(1);
	transition: 0.5s;
	cursor: pointer;
}
.footer-item:hover {
	transform: scale(1.2);
}
.footer-img {
	height: 5rem;
}
.footer-brand {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}
.footer-brand-name {
	font-family: "Friz Quadrata Std Medium", serif;
	font-size: 2.2rem;
	font-weight: 500;
	text-align: center;
}
.developer-details {
	margin: 2rem 0 0rem 1rem;
	font-size: 0.85rem;
	color: rgb(163, 163, 163);
	text-align: right;
}
.footer-dev-email {
	font-weight: 600;
	color: rgb(163, 163, 163) !important;
}
.second-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 1rem;
}
@media only screen and (max-width: 1031px) {
	.footer-wrapper {
		height: 250px;
	}
}
@media only screen and (max-width: 846px) {
	.first-row {
		flex-direction: column;
		align-items: flex-start;
	}
	.footer-wrapper {
		height: 280px;
	}
	.second-row {
		font-size: 0.9rem;
	}
	.footer-address {
		padding-top: 1rem;
	}
	.footer-brand {
		justify-content: start;
		margin-top: 2rem;
	}
	.developer-details {
		text-align: left;
		margin-left: 0;
	}
	.footer-img {
		height: 3.5rem;
	}
}
@media only screen and (max-width: 784px) {
	.footer-wrapper {
		padding: 2rem 3rem;
	}
}
@media only screen and (max-width: 673px) {
	.footer-wrapper {
		padding: 2rem 2rem;
	}

	.footer-item {
		font-size: 1rem;
	}
}
@media only screen and (max-width: 592px) {
	.second-row {
		flex-direction: column;
	}
	.footer-wrapper {
		height: 350px;
	}
}
@media only screen and (max-width: 470px) {
	.footer-wrapper {
		padding-top: 1rem;
		height: 380px;
	}
	.footer-brand-name {
		font-size: 1.3rem;
	}
	.links-row {
		flex-direction: column;
	}
	.first-row {
		flex-direction: row;
	}
	.footer-brand {
		justify-content: end;
	}
	.footer-item {
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
	}
	.developer-details {
		margin-top: 1rem;
	}
}

@media only screen and (max-width: 426px) {
	.footer-wrapper {
		height: 380px;
	}
}
@media only screen and (max-width: 407px) {
	.footer-brand {
		flex-direction: column;
	}
}
