.service-wrapper {
	margin-top: 2rem;
	color: white;
	overflow-x: hidden;
}
.service-title {
	font-size: 2.25rem;
	font-weight: bold;
	text-align: left;
}
.content-title {
	font-size: 2.5rem;
	font-weight: bold;
}
.line {
	height: 5px;
	background-color: white;
	width: 3.5rem;
	margin-top: -1rem;
	border-radius: 10px 0px 10px 0px;
}
.content {
	margin-top: 1.5rem;
	font-size: 1.25rem;
	font-weight: 400;
	text-align: justify;
	line-height: 2rem;
}
.service-image {
	width: 100%;
}
.service-item {
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
}
.content-img-row {
	flex-direction: row !important;
}
.img-content-row {
	flex-direction: row-reverse !important;
}

li.services-list-item {
	font-weight: 500;
	margin-top: 1rem;
}
@media only screen and (max-width: 500px) {
	.content {
		font-size: 1rem;
	}
	.content-title {
		font-size: 1.75rem;
	}
}
