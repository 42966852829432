@import "assets/fonts/friz/font.css";

body {
	font-family: "Montserrat", sans-serif !important;
}

.unselectable {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

@keyframes CTAShimmerTransitioningBackground {
	0% {
		background-position: 1% 0%;
	}
	50% {
		background-position: 99% 100%;
	}
	100% {
		background-position: 1% 0%;
	}
}
.list-group-item.active {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}
.cta {
	margin-top: 15px;
	font-size: 1rem !important;
	font-weight: 700;
	padding: 0.5rem 1rem !important;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	color: #ffffff;
	white-space: nowrap;
	border-radius: 5px;
	border-style: none;
}
.primary {
	background-color: var(--secondary-color);
}
button.cta:disabled,
button.cta[disabled] {
	background-color: rgba(128, 128, 128, 0.725);
	cursor: not-allowed;
}
.background {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	z-index: 0;
}
.shimmerable {
	animation: CTAShimmerTransitioningBackground 10s ease infinite;
	transition: 0.6s;
	position: relative;
	overflow: hidden;
}
.shimmerable::before {
	content: "";
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.5);
	width: 60px;
	height: 100%;
	top: 0;
	filter: blur(30px);
	transform: translateX(-100px) skewX(-15deg);
}
.shimmerable::after {
	content: "";
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.2);
	width: 30px;
	height: 100%;
	top: 0;
	filter: blur(5px);
	transform: translateX(-100px) skewX(-15deg);
}
.shimmerable:hover {
	transform: scale(1.05);
	cursor: pointer;
}
.shimmerable::before,
.shimmerable:hover::after {
	transform: translateX(300px) skewX(-15deg);
	transition: 0.7s;
}
.pointer {
	cursor: pointer;
}

@media only screen and (max-width: 1035px) {
	.navbarCta {
		margin-top: 1rem;
		padding: 0.5rem 0.75rem !important;
		font-size: 0.85rem !important;
	}
}

/* Loader CSS*/
.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid var(--primary-accent-color);
	border-color: var(--primary-accent-color) transparent
		var(--primary-accent-color) transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
