/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Friz Quadrata Std Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Friz Quadrata Std Italic'), url('friz-quadrata-std-italic-587033b2c95df.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Friz Quadrata Std Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Friz Quadrata Std Medium'), url('friz-quadrata-std-medium-5870338ec7ef8.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Friz Quadrata Std Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Friz Quadrata Std Bold'), url('friz-quadrata-std-bold-587034a220f9f.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Friz Quadrata Std Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Friz Quadrata Std Bold Italic'), url('friz-quadrata-std-bold-italic-587033d6d4298.woff') format('woff');
    }